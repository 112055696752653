body {
	margin: 0;
	background-color: #eeeeee;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.chart-header {
	margin-top: 50px;
	margin-bottom: 30px;
}

.main {
	display: flex;
	flex-direction: column;
}

.chart-container {
	margin-top: 50px;
	margin-bottom: 30px;
}

.dash-container {
	display: flex;
	flex-direction: column;
}
.dash-item {
	width: 300px;
	height: 200px;
}

.dash-header {
	background-color: #fff;
	border-color: #fff;
	border-radius: 5px;
	padding: 20px;
	margin: 20px;
	box-shadow: 0 6px 6px rgba(50, 50, 93, 0.05), 0 5px 15px rgba(0, 0, 0, 0.05);
}

.card {
	align-items: center;
	border-color: #fff;
	border-radius: 10px;
	padding: 20px;
	margin: 20px;
	box-shadow: 0 6px 6px rgba(50, 50, 93, 0.05), 0 5px 15px rgba(0, 0, 0, 0.05);
}

.button {
	width: 500px;
	margin-top: 40px;
	margin-bottom: 10px;
}

.chart-nav {
	box-shadow: 0 4px 6px -6px #222;
}
